<template>
    <div id="MemberDetails">
        <div class="left">
            <section id="member-info" class="login-form-style">
                <h2>登録情報</h2>
                <ElForm
                    label-position="top"
                    label-width="200px"
                    ref="memberDetailsFormRef"
                    :model="memberDetailsFormData"
                    :rules="memberDetailsFormRules"
                >
                    <ElFormItem
                        class="f14 f14-mb"
                        label="基本セミナー修了 神学科の履修権限"
                        prop="select1"
                    >
                        <ElRadioGroup v-model="memberDetailsFormData.level">
                            <ElRadio :label="3">許可</ElRadio>
                            <ElRadio :label="2">不許可</ElRadio>
                        </ElRadioGroup>
                    </ElFormItem>
                    <ElFormItem
                        class="f14 f14-mb"
                        label="メールアドレス"
                        prop="email"
                    >
                        <ElInput
                            v-model="memberDetailsFormData.email"
                            type="email"
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem class="f14 f14-mb" label="名前" prop="name">
                        <ElInput
                            v-model="memberDetailsFormData.name"
                            type="name"
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem
                        class="f14 f14-mb"
                        label="ふりがな"
                        prop="furigana"
                    >
                        <ElInput
                            v-model="memberDetailsFormData.furigana"
                            type="furigana"
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem
                        class="f14 f14-mb"
                        label="生年月日"
                        prop="birth"
                    >
                        <ElDatePicker
                            v-model="memberDetailsFormData.birth"
                            type="date"
                            format="YYYY/MM/DD"
                            value-format="YYYY/MM/DD"
                        ></ElDatePicker>
                    </ElFormItem>
                    <ElFormItem
                        class="f14 f14-mb"
                        label="電話番号"
                        prop="phone"
                    >
                        <ElInput
                            v-model="memberDetailsFormData.phone"
                            type="phone"
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem class="f14 f14-mb" label="住所" prop="address">
                        <ElInput
                            v-model="memberDetailsFormData.address"
                            type="address"
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem class="f14 f14-mb" label="職業" prop="job">
                        <ElInput v-model="memberDetailsFormData.job"></ElInput>
                    </ElFormItem>
                    <ElFormItem
                        class="f14 f14-mb"
                        label="親育番号"
                        prop="memberId"
                    >
                        <ElInput
                            v-model="memberDetailsFormData.memberId"
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem
                        class="f14 f14-mb"
                        label="真資格"
                        prop="qualification"
                    >
                        <ElInput
                            v-model="memberDetailsFormData.qualification"
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem class="f14 f14-mb" label="担当真使" prop="role">
                        <ElInput v-model="memberDetailsFormData.role"></ElInput>
                    </ElFormItem>
                    <ElFormItem
                        class="f14 f14-mb"
                        label="紹介者"
                        prop="recommender"
                    >
                        <ElInput
                            v-model="memberDetailsFormData.recommender"
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem
                        class="f14 f14-mb"
                        label="所属親交所"
                        prop="location"
                    >
                        <ElInput
                            v-model="memberDetailsFormData.location"
                        ></ElInput>
                    </ElFormItem>
                    <div class="button-wrap">
                        <button
                            class="f18 f16-mb"
                            @click.prevent="submitMemberDetailsForm"
                        >
                            変更内容を保存
                        </button>
                        <button
                            class="f18 f16-mb"
                            @click.prevent="deleteMember"
                        >
                            界員データを削除
                        </button>
                    </div>
                </ElForm>
            </section>
        </div>
        <div class="right">
            <section id="donate-record" class="simple-table-style">
                <h2>おつなぎ徳積みの記録</h2>
                <div class="sort-wrap">
                    <ElFormItem label="ソート">
                        <ElSelect v-model="donateSort" @change="initDonateData">
                            <ElOption
                                v-for="item in donateSortOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value"
                            ></ElOption>
                        </ElSelect>
                    </ElFormItem>
                </div>
                <ElTable :data="memberDonateData" style="width: 100%">
                    <ElTableColumn prop="date" label="日付" width="150" />
                    <ElTableColumn
                        prop="amount"
                        label="おつなぎ徳積み額"
                        width="150"
                    >
                        <template #default="scope">
                            {{ dollarFormatter(scope.row.amount) }}
                        </template>
                    </ElTableColumn>
                    <ElTableColumn prop="subCategory" label="備考" />
                </ElTable>
            </section>
            <section id="course-record" class="simple-table-style">
                <h2>学修記録管理</h2>
                <div class="sort-wrap">
                    <ElFormItem label="ソート">
                        <ElSelect v-model="courseSort" @change="initCourseData">
                            <ElOption
                                v-for="item in courseSortOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value"
                            ></ElOption>
                        </ElSelect>
                    </ElFormItem>
                </div>
                <ElTable :data="memberCourseData" style="width: 100%">
                    <ElTableColumn prop="createdAt" label="日付" width="150" />
                    <ElTableColumn prop="subCategory" label="学修" />
                    <ElTableColumn prop="number" label="コース番号" width="100">
                        <template #default="scope">
                            {{ `#${scope.row.number}` }}
                        </template>
                    </ElTableColumn>
                    <ElTableColumn prop="remark" label="備考" />
                    <ElTableColumn label="修正" width="60">
                        <template #default="scope">
                            <ElIcon
                                @click="
                                    openEditDialog(
                                        scope.row.recordId,
                                        scope.row.remark
                                    )
                                "
                                ><EditPen
                            /></ElIcon>
                        </template>
                    </ElTableColumn>
                </ElTable>
            </section>
        </div>
        <ElDialog v-model="showCourseEditDialog" title="修正備考" center>
            <div class="login-form-style">
                <ElForm
                    :label-position="store.getters.labelPosition"
                    label-width="120px"
                >
                    <ElFormItem label="備考">
                        <ElInput v-model="courseEditForm.remark"></ElInput>
                    </ElFormItem>
                    <div class="button-wrap">
                        <button
                            class="f18 f16-mb"
                            @click.prevent="submitCourseEditFrom"
                        >
                            修正
                        </button>
                    </div>
                </ElForm>
            </div>
        </ElDialog>
    </div>
</template>
<script>
export default {
    name: 'MemberDetails',
}
</script>
<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { dollarFormatter } from '@/utils/common'
import { getUserDetailsFormRules } from '@/utils/formRules'
import {
    getMemberData,
    updateMemberData,
    getUserDonateDetail,
    getMemberCourseRecord,
    updateMemberCourseRecord,
    deleteUser,
} from '@/service/api'
import { onBeforeRouteLeave } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
import { EditPen } from '@element-plus/icons-vue'
const store = useStore()
const route = useRoute()
onMounted(async () => {
    let res = await getMemberData(route.params.userId)
    originMemberData.value = res.data
    initMemberData()
    await initDonateData()
    await initCourseData()
})
onBeforeRouteLeave((to, from, next) => {
    let flag = false
    for (let key of Object.keys(memberDetailsFormData)) {
        if (memberDetailsFormData[key] != originMemberData.value[key]) {
            flag = true
            break
        }
    }
    if (flag) {
        ElMessageBox.confirm(
            '変更内容が保存されていません。このままページを移動してよろしいですか？<br>変更を保存する場合は［変更内容を保存］ボタンを押してください',
            '警告',
            {
                confirmButtonText: 'はい',
                cancelButtonText: 'キャンセル',
                type: 'warning',
                dangerouslyUseHTMLString: true,
            }
        )
            .then(() => {
                next()
            })
            .catch(() => {
                return false
            })
    } else {
        next()
    }
})

const originMemberData = ref({})
function initMemberData() {
    Object.keys(originMemberData.value).forEach((key) => {
        memberDetailsFormData[key] = originMemberData.value[key]
    })
    if (memberDetailsFormData.level > 3) memberDetailsFormData.level = 3
    if (memberDetailsFormData.level < 2) memberDetailsFormData.level = 2
}
const memberDetailsFormRef = ref(null)
const memberDetailsFormData = reactive({
    level: 0,
    userId: '',
    email: '',
    name: '',
    birth: '',
    phone: '',
    address: '',
    job: '',
    memberId: '',
    qualification: '',
    role: '',
    recommender: '',
    location: '',
    furigana: '',
})
const memberDetailsFormRules = getUserDetailsFormRules(memberDetailsFormData)
function submitMemberDetailsForm() {
    memberDetailsFormRef.value.validate(async (valid) => {
        if (valid) {
            await updateMemberData(
                memberDetailsFormData,
                originMemberData.value
            )
            Object.keys(originMemberData.value).forEach((key) => {
                originMemberData.value[key] = memberDetailsFormData[key]
            })
        }
    })
}

const donateSort = ref('createdAt,desc')
const donateSortOptions = [
    { value: 'createdAt,desc', label: 'おつなぎ徳積みの日付 降順' },
    { value: 'createdAt,asc', label: 'おつなぎ徳積みの日付 昇順' },
    { value: 'amount,desc', label: 'おつなぎ徳積み額 降順' },
    { value: 'amount,asc', label: 'おつなぎ徳積み額 昇順' },
]
const memberDonateData = ref([])
async function initDonateData() {
    let res = await getUserDonateDetail(
        donateSort.value,
        originMemberData.value.userId
    )
    memberDonateData.value = res.data
}

const courseSort = ref('createdAt,desc')
const courseSortOptions = [
    { value: 'createdAt,desc', label: '学修行った日 降順' },
    { value: 'createdAt,asc', label: '学修行った日 昇順' },
    { value: 'course,asc', label: '学修正順' },
    { value: 'course,desc', label: '学修逆順' },
]
const memberCourseData = ref([])
async function initCourseData() {
    let res = await getMemberCourseRecord(
        courseSort.value,
        originMemberData.value.userId
    )
    memberCourseData.value = res.data
}

function openEditDialog(recordId, remark) {
    showCourseEditDialog.value = true
    courseEditForm.recordId = recordId
    courseEditForm.remark = remark
}
const showCourseEditDialog = ref(false)
const courseEditForm = reactive({
    recordId: '',
    remark: '',
})
async function submitCourseEditFrom() {
    if (!courseEditForm.recordId) {
        ElMessage.error(
            '一時的なエラーにより、問題が発生しています。少しお時間を置いてから再度お試しください。'
        )
        showCourseEditDialog.value = false
        courseEditForm.remark = ''
    } else {
        await updateMemberCourseRecord(
            courseEditForm.recordId,
            courseEditForm.remark
        ).then(() => {
            ElMessage.success('変更内容が更新されました。')
            showCourseEditDialog.value = false
            initCourseData()
        })
    }
}

async function deleteMember() {
    ElMessageBox.confirm(
        'このユーザーアカウントとデーターを削除しますか？',
        '警告',
        {
            confirmButtonText: 'はい',
            cancelButtonText: 'キャンセル',
            type: 'warning',
        }
    )
        .then(() => {
            deleteUser(originMemberData.value.userId)
        })
        .catch(() => {
            return false
        })
}
</script>
<style lang="scss" scoped>
#MemberDetails {
    display: flex;
    h2 {
        margin-bottom: 1em;
    }
    .left {
        flex: 0 1 300px;
        margin-right: 60px;
    }
    .right {
        flex: 1 1;

        #course-record {
            margin-top: 50px;
        }
        /deep/ .el-icon {
            cursor: pointer;
        }
    }
    .button-wrap {
        margin-top: 30px;
        flex-direction: column;
        align-items: center;
        button {
            margin-top: 20px;
        }
    }
}
</style>
